
import Menu from './components/Menu';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import Footer from './components/Footer';
import Autenticacion from './components/Autenticacion';
import Estadisticas from './components/Estadisticas';
import ValidarPapeleta from './components/ValidarPapeleta';
import ValidarVoto from './components/ValidarVoto';
import Votaciones from './components/Votaciones';
import Votar from './components/Votar';
import Fvoto from './components/Fvoto';


const App = () => {
  return (
    
      
    <div className="app" >
     
      <Menu />
        <Routes> 
          
          <Route path="/" exact  element={<Autenticacion/>} />
          <Route path="/fvoto/:id"   element={<Fvoto/>} />
          <Route  path="/estadisticas" exact  element={<Estadisticas/>} />
          <Route path="/validarp" element={<ValidarPapeleta/>} />
          <Route path="/validarv" element={<ValidarVoto/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/*****---" element={<Votaciones/>} />
          <Route path="/votar/"  element={<Votar/>} />
         
        </Routes>
    
        <br/>
        <br/>
        <Footer />
        <script src="http://code.jquery.com/jquery-1.11.0.min.js"></script>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/3.3.4/inputmask/inputmask.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/3.3.4/jquery.inputmask.bundle.js"></script>
<link href="https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/3.3.4/css/inputmask.min.css" rel="stylesheet"/>
      </div>
     
  );
 }
 

export default App;
