import React , { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { environment } from "../enviroments/enviroment";
import Swal from 'sweetalert2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const ValidarPapeleta = () => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [rut, setRut]  = useState("");
  const [esta, setEsta] = React.useState(null);
  const [post, setPost] = React.useState(null);
  const { validate, clean, format, getCheckDigit } = require('rut.js')
  const handleSubmit = async(e) => {
    e.preventDefault();
   

    const userData = {
        rut:rut,
      };
      
      axios.get(`${environment.api}votacion/votacionesDelUsuario/`+userData.rut).then((response) => {
        

        console.log('ddsd',response);
        if(response.data!==''){

          
          
           setEsta(<div className={classes2.root}>
            <List component="nav" aria-label="main mailbox folders">
            {response.data.map((row) => (
              <ListItem button>
                <ListItemIcon>
                  <HowToVoteIcon />
                </ListItemIcon>
                <ListItemText primary={'Nombre: '+row.nombre}  />
              </ListItem>
               ))}
            </List>
          </div>)
        }
  
    }).catch((error) => {
      // Error
      
     
      if(error.response.data.message == 'votante no encontrado'){
        Swal.fire('Estimado/a', 'Su rut no se encuentra en nuestra base de datos comuniquese con su directiva!', 'info');
       }
       if(error.response.data.message == 'rut invalido'){
        Swal.fire('Estimado/a', 'El rut ingresado no es valido !', 'info');
      }
     
      
     

  });
    }

   
  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}>
      <h1 style={{textAlign: "center"}}>Validar Papeletas</h1>
      <br/>
      <br/>
      <Box component="form" onSubmit={handleSubmit}>
      <TextField id="standard-basic" required label="rut sin puntos y con guion" value={rut} type="text" onChange={e => setRut(format(e.target.value ,{ dots: false }))}   fullWidth inputProps={{ maxLength: 12 }} placeholder="11877009-9" />
      
      <br/>
      <br/>
      <Button style={{backgroundColor: "#87CEEB",color: "white"}}
  type="submit"
  sx="{{mt:2}}"
        variant="contained"
        color="secondary"
        className={classes.button}
        endIcon={<Icon><SendIcon /></Icon>}
      >
        Consultar
      </Button>
      </Box>

      <br/>
      <br/>

      {esta}
    </div>
  )
}

export default ValidarPapeleta
