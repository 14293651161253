import React , { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { environment } from "../enviroments/enviroment";
import Swal from 'sweetalert2';
import { RiH1 } from 'react-icons/ri';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));


export default function ValidarVoto() {

  const classes = useStyles();
  const [rut, setRut]  = useState("");
  const [esta, setEsta] = React.useState(null);
  const handleSubmit = async(e) => {
    e.preventDefault();
   

    const userData = {
        rut:rut,
      };
      
      axios.get(`${environment.api}votacion/votoUsuarioCosulta/`+userData.rut).then((response) => {
        

       
        if(response.data.length > 0) {
          if(response.data.length > 1){
           setEsta(<strong><h2><strong>Cantidad de Votos emitidos: {response.data.length} votos </strong></h2></strong>)
          }
          else{
            setEsta(<strong><h2><strong>Cantidad de Votos emitidos: {response.data.length} voto </strong></h2></strong>)
          }
        }
        else{
          setEsta(<strong><h2><strong>No existen votos </strong></h2></strong>)
        }
  
    }).catch((error) => {
      // Error
      
     
      if(error.response.data.message == 'votante no encontrado'){
        Swal.fire('Estimado/a', 'Su rut no se encuentra en nuestra base de datos comuniquese con su directiva!', 'info');
       }
       if(error.response.data.message == 'rut invalido'){
        Swal.fire('Estimado/a', 'El rut ingresado no es valido !', 'info');
      }
      if(error.response.data.message == 'no hay votos'){
        setEsta(<strong><h2><strong>No existen votos </strong></h2></strong>)
        }
      
     

  });
    }
    const { validate, clean, format, getCheckDigit } = require('rut.js')
  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}>
      <h1 style={{textAlign: "center"}}>Validar votos</h1>
      <br/>
      <br/>
      <Box component="form" onSubmit={handleSubmit}>
      <TextField id="standard-basic" required label="rut sin puntos y con guion" value={rut} type="text" onChange={e => setRut(format(e.target.value ,{ dots: false }))}   fullWidth inputProps={{ maxLength: 12 }} placeholder="11877009-9" />
      
      <br/>
      <br/>
      <Button style={{backgroundColor: "#87CEEB",color: "white"}}
  type="submit"
  sx="{{mt:2}}"
        variant="contained"
        color="secondary"
        className={classes.button}
        endIcon={<Icon><SendIcon /></Icon>}
      >
        Consultar
      </Button>
      </Box>

      <br/>
      <br/>

      {esta}
    </div>
  )
}
