import React from 'react'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Box } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
export default function Footer() {
  return (
    <Box style={{
     backgroundColor: '#8db600',
     color: 'white'
    }}
    component="footer"
   
  >
    <Container maxWidth="xl">
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="text.primary" gutterBottom>
           Sobre Nosotros
          </Typography>
          <Typography variant="body2" color="text.secondary">
           Empresa de desarrollo de software dedicado a la votación electrónica
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Contacto
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Avenida Providencia,
N*1208,
oficina 207,
Providencia
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Email: admin@fairvoting.cl
          </Typography>
          <Typography variant="body2" color="text.secondary">
            celular: +569 87827775
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="text.primary" gutterBottom>
           Siguenos
          </Typography>
          <Link href="https://www.facebook.com/profile.php?id=61550615844805" color="inherit">
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.instagram.com/fairvoting2023/"
            color="inherit"
            sx={{ pl: 1, pr: 1 }}
          >
            <InstagramIcon />
          </Link>
          <Link href="https://www.linkedin.com/company/100323862/admin/feed/posts/" color="inherit">
            <LinkedInIcon />
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://fairvoting.cl/">
            LFCBSERVICES
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Container>
  </Box>
  )
}
