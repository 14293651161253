import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { environment } from './../enviroments/enviroment';
import GavelIcon from '@material-ui/icons/Gavel';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import { Chart } from "react-google-charts";
import { DataGrid ,GridColDef } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {format,parseISO } from 'date-fns'
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Estadisticas() {

  const classes = useStyles();
  const classes2 = useStyles2();
  const [votaciones, setVotaciones] = React.useState(null);
  const [votacion, setVotacion] = React.useState(null);
  const [config, setConfig] = React.useState(null);
  const [estado, setEstado] = React.useState(null);
  const [esta, setEsta] = React.useState(null);
  const [esta2, setEsta2] = React.useState(null);
  const [esta3, setEsta3] = React.useState(null);
  const [esta4, setEsta4] = React.useState(null);
  const [esta5, setEsta5] = React.useState(null);
  const [grafico1, setGrafico1] = React.useState(null);
  const [ff, setFf] = React.useState(null);
  const [grafico2, setGrafico2] = React.useState(null);
  const [grafico3, setGrafico3] = React.useState(null);
  const [tabla, setTabla] = React.useState(null);
  const [traer, setTraer] = React.useState(null);
    React.useEffect(() => {
      axios.get(`${environment.api}votacion`).then((response) => {
        setVotaciones(response.data);

      });

      
     const today = new Date();
      axios.get(`${environment.api}configuracion/1`).then((response) => {
        setConfig(response.data);
       console.log('fechas',response.data);
       const [dateValues, timeValues] = response.data.fechaApertura.split(' ');

         const [dateValues2, timeValues2] = response.data.fechaCierre.split(' ');

        const [year,month, day] = dateValues.split('-');

   const [year2,month2, day2] = dateValues2.split('-');

   const [hours, minutes, seconds] = timeValues.split(':');

   const [hours2, minutes2, seconds2] = timeValues2.split(':');

   const date = new Date(
    +year,
    +month - 1,
    +day,
    +hours,
    +minutes,
    +seconds,
  );

  const date2 = new Date(
   +year2,
   +month2 - 1,
   +day2,
   +hours2,
   +minutes2,
   +seconds2,
 );

 if(date > today ){
  setEstado('no iniciada');
 }
 else{
  setEstado('en proceso');
 }

 if(today > date2 ){
  setEstado('proceso finalizado');
 }


      });

      

    }, []);
  
    if (!votaciones) return null;
  

  const handleChange = (event) => {
    setTraer(null);
    setVotacion(event.target.value);
setFf(event.target.value);

console.log('valor',ff);
    console.log('votacion escogida',event.target.value);
    
   
    axios.get(`${environment.api}votacion/${event.target.value}`).then((rr) => {

      setEsta4(
        <ListItem button>
        <ListItemIcon>
          <GavelIcon />
        </ListItemIcon>
        <ListItemText primary={'Fecha Apertura: '+format(parseISO(rr.data.fechaApertura), 'dd/MM/yyyy HH:mm:ss')} />
      </ListItem>
    );

    setEsta5(
      <ListItem button>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <ListItemText primary={'Fecha Cierre: '+format(parseISO(rr.data.fechaCierre), 'dd/MM/yyyy HH:mm:ss')} />
    </ListItem>
  );

      setEsta2(
      <ListItem button>
      <ListItemIcon>
        <GavelIcon />
      </ListItemIcon>
      <ListItemText primary={'Máximo de preferencias: '+rr.data.maximoOpciones} />
    </ListItem>
  );

  setEsta3(
    <ListItem button>
    <ListItemIcon>
      <HourglassFullIcon />
    </ListItemIcon>
    <ListItemText primary={'Estado de la votación: '+rr.data.estado+' - '+estado} />
    </ListItem>  
  )


      
    });

 
    axios.get(`${environment.api}votacion/votosPorHora/${event.target.value}`).then((hora) => {
    
  
      const columns: GridColDef<(typeof rows)[number]>[] = [
        { field: 'id', headerName: 'HORARIO' ,width: 190},

       

        { field: 'cantidad', headerName: 'VOTOS', width: 190,},
      
       
        
      ];
      const rows = [];
      hora.data.forEach((numero, index) => {
        
        
        rows.push({ id: numero.fecha.replace("July","Julio").replace("January","Enero")
          .replace("February","Febrero")
          .replace("Marzo","March")
          .replace("April","Abril")
          .replace("May","Mayo")
          .replace("June","Junio")
          .replace("August","Agosto")
          .replace("September","Septiembre")
          .replace("October","Octubre")
          .replace("November","Noviembre")
          .replace("December","Diciembre")
          ,cantidad: numero.total}

        )
    });

   
     
      
     
      if(hora.data.length > 0){
    
      setTabla(<Box sx={{ height: 400, width: '100%' }}>
        <DataGrid 
        localeText={{
          noRowsLabel: "No se ha encontrado datos.",
         
          noResultsOverlayLabel: "No se ha encontrado ningún resultado",
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          toolbarFilters: "Filtros",
          toolbarFiltersLabel: "Ver filtros",
          toolbarFiltersTooltipHide: "Quitar filtros",
          toolbarFiltersTooltipShow: "Ver filtros",
      }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          
          disableRowSelectionOnClick
        />
      </Box>)
      }
      
    }).catch((error) => {
      // Error
      console.log(error.response.data.message);

  });

  
    
    

    axios.post(`${environment.api}resultado/crear/${event.target.value}`).then((rr) => {
    
      console.log('lo hice ',rr);
      
    }).catch((error) => {
      // Error
      console.log(error.response.data.message);

  });

    

    
   
    
  };
  function traerDatos(){

    setTraer('fddf');
    axios.get(`${environment.api}resultado/resultadoTraido/`+ff).then((response) => {
      console.log('votacion: ',response.data)

      const options = {
        title: "Votos emitidos VS Votos no emitidos",
        is3D: true,
      };

      const data = [
        ["Task", "Hours per Day"],
        ["Votos emitidos", response.data.totalEmitidos],
        ["Votos no emitidos", response.data.totalIncritos - response.data.totalEmitidos],
        
      ];

      console.log('mm',response.data)
      if(response.data.totalEmitidos > 0){
        setGrafico1(<Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"100%"}
          height={"400px"}
        />)
      }



      const options2 = {
        title: "Votos emitidos Hombres VS Votos no emitidos Hombres",
        is3D: true,
      };

      const data2 = [
        ["Task", "Hours per Day"],
        ["Votos emitidos", response.data.emitidosHombre],
        ["Votos no emitidos", response.data.totalHombreIncritos - response.data.emitidosHombre],
        
      ];

      if(response.data.totalHombreIncritos!=null && response.data.totalHombreIncritos > 0){
        setGrafico2(    <Chart
          chartType="PieChart"
          data={data2}
          options={options2}
          width={"100%"}
          height={"400px"}
        />)
      }

      const options3 = {
        title: "Votos emitidos Mujeres VS Votos no emitidos Mujeres",
        is3D: true,
      };

      const data3 = [
        ["Task", "Hours per Day"],
        ["Votos emitidos", response.data.emitidosMujer],
        ["Votos no emitidos", response.data.totalMujeresIncritas - response.data.emitidosMujer],
        
      ];
      if(response.data.totalMujeresIncritas!=null && response.data.totalMujeresIncritas > 0){
        setGrafico3(    <Chart
          chartType="PieChart"
          data={data3}
          options={options3}
          width={"100%"}
          height={"400px"}
        />)
      }

      setEsta(<div style={{
        textAlign: "center",
        
      }} className={classes2.root}>
        <List   component="nav" aria-label="main mailbox folders">
         
          <ListItem button>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Total inscritos: '+response.data.totalIncritos} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Incritos Hombres: '+response.data.totalHombreIncritos} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Incritos Mujeres: '+response.data.totalMujeresIncritas} />
          </ListItem>
          <Divider />
          { response.data.totalEmitidos!=null  ? (
          <ListItem button>
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos: '+response.data.totalEmitidos+' votos'} />
          </ListItem>
          
          ) : (
            <ListItem button>
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos: 0 votos'} />
          </ListItem>
          )}
        { response.data.emitidosHombre!==null && response.data.totalHombreIncritos!=null ? (
          <ListItem  button res >
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos Hombres:  '+response.data.emitidosHombre+' votos'} />
          </ListItem>
        ) : (
<ListItem  button res >
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos Hombres:  0 votos'} />
          </ListItem>
        )}

{ response.data.emitidosMujer!==null && response.data.totalMujeresIncritas!=null ? (
          <ListItem  button res >
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos Mujeres:  '+response.data.emitidosMujer+' votos'} />
          </ListItem>
        ) : (
<ListItem  button res >
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText  primary={'Total Votos Recibidos Mujeres:  0 votos'} />
          </ListItem>
        )}
<Divider />
{ response.data.porcentajeTotalEmitidos!==null ? (
          <ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Porcentaje de participación:  '+Math.round(response.data.porcentajeTotalEmitidos)+'%'} />
          </ListItem>
        ) : (
<ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Porcentaje de participación:  0%'} />
          </ListItem>
        )}

{ response.data.porcentajeEmitidosHombre!==null && response.data.totalHombreIncritos!=null  ? (
          <ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Participación hombres:  '+Math.round(response.data.porcentajeEmitidosHombre)+'%'} />
          </ListItem>
        ) : (
<ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Participación hombres:  0%'} />
          </ListItem>
        )}

{ response.data.porcentajeEmitidosMujer!==null && response.data.totalMujeresIncritas!=null  ? (
          <ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Participación mujeres:  '+Math.round(response.data.porcentajeEmitidosMujer)+'%'} />
          </ListItem>
        ) : (
<ListItem  button res >
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText  primary={'Participación mujeres:  0%'} />
          </ListItem>
        )}
        </List>
        <Divider />
        
        </div>
        );
    });

  }
  return (
    <div style={{
      textAlign: "center",
      maxWidth: "950px",
      margin: "0 auto",
      border: "1px solid #e6e6e6",
      padding: "40px 25px",
      marginTop: "50px"
    }}>
       <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Seleccione Votación</InputLabel>
        <Select 
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={votacion}
          onChange={handleChange}
        >
         {votaciones?.map(option => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.nombre}
            </MenuItem>
          );
      })}
        </Select>
        <br/>
  
        <Button style={{backgroundColor: "#87CEEB",color: "white"}}
  onClick={() => {
    traerDatos()
    }}
  sx="{{mt:2}}"
        variant="contained"
        color="secondary"
        className={classes.button}
        endIcon={<Icon><SearchIcon /></Icon>}
      >
        Consultar
      </Button>
      </FormControl>
     { traer!=null &&
      <div>
        {esta4}
      {esta5}
      {esta2}
      {esta3}
      {esta}
      
     
     {grafico1}
     {grafico2}
     {grafico3}
     {tabla}
      
     </div>
}
    </div>
  )
}
