import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';
import BallotIcon from '@material-ui/icons/Ballot';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import DonutSmallIcon from '@material-ui/icons/DonutSmall';
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu style={{backgroundColor: "red",color: "white"}}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
  
    <Menu 
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
       
      
      <Link to='/home' >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" style={{color:"#87CEEB"}}>
          
            <HomeIcon />
         
        </IconButton>
        <strong>  <p style={{
      color:'black',
    }}>Inicio</p></strong>
      </MenuItem>
      </Link>
      <Link to='/validarv' >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" style={{color:"#87CEEB"}}>
       
            <VerifiedUserIcon />
         
        </IconButton>
        <strong>  <p style={{
      color:'black',
    }}>Validar Voto</p></strong>
      </MenuItem>
      </Link>

      <Link to='/validarp' >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" style={{color:"#87CEEB"}}>
       
            <BallotIcon />
         
        </IconButton>
        <strong>   <p style={{
      color:'black',
    }}>Consultar Papeletas</p></strong>
      </MenuItem>
      </Link>

      <Link to='/estadisticas'  >
      <MenuItem >
        <IconButton aria-label="show 11 new notifications" style={{color:"#87CEEB"}}>
       
            <DonutSmallIcon />
         
        </IconButton>
        <strong> <p style={{
      color:'black',
    }} >Estadisticas</p></strong>

      </MenuItem>
      </Link>
    
    </Menu>
  );

  return (
    <div className={classes.grow} >
      <AppBar position="static" style={{backgroundColor: "#87CEEB",color: "white"}}>
        <Toolbar>
        
         
          
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
          <Link to='/home' unstable_viewTransition> 
            <IconButton aria-label="show 4 new mails" style={{
      color:'white',
    }} >
              
                <HomeIcon />
                {"Inicio"}
            </IconButton>
            </Link>
            <Link to='/validarv' state={{ path: "DD" }} > 
            <IconButton aria-label="show 17 new notifications" style={{
      color:'white',
    }}>
             
                <VerifiedUserIcon />
                {"Validar Voto"}
            </IconButton>
            </Link>

            <Link to='/validarp' > 
            <IconButton aria-label="show 17 new notifications" style={{
      color:'white',
    }}>
             
                <BallotIcon />
                {"Consultar Papeletas"}
            </IconButton>
            </Link>
            <Link to='/estadisticas' > 
            <IconButton aria-label="show 17 new notifications" style={{
      color:'white',
    }}>
             
                <DonutSmallIcon />
                {"Estadisticas"}
            </IconButton>
            </Link>
          
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
